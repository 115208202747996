<template>
  <div class="v-play-history">
    <base-layout>
      <my-music-function-bar :config="functionConfig"/>
      <my-music-list :config="listConfig" :contentData="contentData"/>
    </base-layout>
  </div>
</template>
<script>
import { defineComponent, provide } from 'vue';

import BaseLayout from '@/components/MyMusic/BaseLayout.vue';
import MyMusicFunctionBar from '@/components/MyMusic/MyMusicFunctionBar.vue';
import MyMusicList from '@/components/MyMusic/MyMusicList/index.vue';
import {
  functionConfig,
  contentData,
  listConfig,
  musicDetailColumns,
  musicDetailContentData,
  trackDivideColumns,
  trackDivideData,
} from '@/config/myMusic/playHistoryConfig';

export default defineComponent({
  name: 'MyMusicPlayHistory',
  components: {
    BaseLayout,
    MyMusicList,
    MyMusicFunctionBar,
  },
  setup() {
    const listContentConfig = {
      notice: '※ 僅保留近一週之紀錄',
      expandDetail: 'music-detail', // list-detail, music-detail, ''
    };

    provide('listContentConfig', listContentConfig);
    const musicDetail = {
      versionAndEdit: {
        columns: musicDetailColumns,
        data: musicDetailContentData,
      },
      track: {
        columns: trackDivideColumns,
        data: trackDivideData,
      },
    };
    provide('musicDetail', musicDetail);

    return {
      functionConfig, listConfig, contentData,
    };
  },
});
</script>
<style lang="scss" scoped></style>
