<template>
  <div class="c-more">
    <img class="c-more__img" src="@/assets/myMusic/icon_more.svg" @click="handleExpandStatus" />
    <n-collapse-transition :show="expandStatus">
      <div class="more-list-wrap">
        <ul class="more-list">
          <li class="more-list__item" v-for="item of list" :key="item.key" @click="handleClick(item.key)">
            {{ item.label }}
          </li>
        </ul>
      </div>
    </n-collapse-transition>
    <n-modal v-model:show="modelStatus['activity-share-record']" preset="dialog" :show-icon="false">
      <model-base-layout title="開心的時候聽了更開心">
        <activity-share-record :activityTab="activityShareRecordTab" />
      </model-base-layout>
    </n-modal>

    <n-modal v-model:show="modelStatus['add-to-playlist']" preset="dialog" :show-icon="false">
      <model-base-layout title="新增至歌單" decoLine>
        <add />
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue';
import { NCollapseTransition, NModal } from 'naive-ui';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import ActivityShareRecord from '@/components/Global/Popup/ActivityShareRecord.vue';
import Add from '@/components/Global/Popup/Add.vue';

export default defineComponent({
  name: 'PlayHistoryMore',
  components: {
    NCollapseTransition,
    NModal,
    ModelBaseLayout,
    ActivityShareRecord,
    Add,
  },

  setup() {
    const expandStatus = ref(false);

    const handleExpandStatus = () => {
      expandStatus.value = !expandStatus.value;
    };

    const list = [
      {
        key: 'to-album-page',
        label: '前往專輯頁面',
      },
      {
        key: 'add-playlist',
        label: '新增至播放序列',
      },
      {
        key: 'add-to-playlist',
        label: '新增至歌單',
      },
    ];

    const modelStatus = reactive({
      'to-album-page': false,
      'add-playlist': false,
      'add-to-playlist': false,
    });

    const handleClick = (key) => {
      modelStatus[key] = true;
    };

    return {
      modelStatus,
      handleClick,
      list,
      expandStatus,
      handleExpandStatus,

    };
  },
});
</script>
<style lang="scss" scoped>
.c-more {
  position: relative;

  &__img {
    cursor: pointer;
    width: 16px;
  }
}
.more-list-wrap {
  @include position(tl, 100%, 0);
  @include flex(center);
  @include padding(20px 0);
  width: 135px;
  background-color: $c-white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #00000029;
  z-index: 10;
}

.more-list {
  &__item {
    @include font-style($c-assist4, 16, normal, 0.8px, 24px);
    cursor: pointer;

    & + & {
      margin-top: 12px;
    }
  }
}
</style>
